const getInitialState = () => ({
	isSignupFormVisible: true,
	nameField: {
		value: '',
		errorMessage: null
	},
	companyField: {
		value: '',
		errorMessage: null
	},
	emailField: {
		value: '',
		errorMessage: null
	},
	passwordField: {
		value: '',
		errorMessage: null
	},
	termsCheckbox: {
		value: false,
		errorMessage: null
	},
	tipsCheckbox: {
		value: false
	},
	signupPlan: null,
	signupPlanGroup: null,
	signupPlanEvents: null,
	signupPlanActiveContacts: null,
	signupCoupon: null,
	isOauthSignupButtonVisible: true,
	isPipedriveOauthVisible: true,
	isHubspotOauthVisible: true,
	isTermsConfirmationDialogVisible: false,
	generalErrors: []
});

export default getInitialState;
