const SortFields = {
	UPDATED_AT: 'updatedAt'
};

const SortOrders = {
	ASC: 'asc',
	DESC: 'desc'
};

export const getInitialState = () => ({
	connectionId: null,
	connection: null,
	connectionStatus: {
		synced: 0,
		failed: 0
	},
	primaryRuleStatus: {},
	secondaryRuleStatus: {},
	contacts: [],
	pagination: {
		currentPage: 1,
		pageCount: 0
	},
	generalErrors: [],
	downloadUrl: null,
	sortByField: SortFields.UPDATED_AT,
	sortOrder: SortOrders.DESC,
	contactStatus: '',
	searchTerm: '',
	intervalId: null,
	contactSyncRules: []
});
