//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PipedriveIcon from 'images/pipedrive-icon.svg';
import PipedriveIconTransparent from 'images/pipedrive-icon-transparent.svg';
import HubspotIconTransparent from 'images/hubspot-icon-transparent.svg';
import CopperIcon from 'images/copper-icon.svg';
import MailchimpIcon from 'images/mailchimp-icon.svg';
import OutfunnelIcon from 'images/outfunnel-icon.svg';
import HubspotIcon from 'images/hubspot-icon.svg';
import WixIcon from 'images/wix-icon.svg';
import ZendeskSellIcon from 'images/zendesk-sell-icon.svg';
import CsvFileIcon from 'images/csv-file-icon.svg';
import ActivecampaignIcon from 'images/activecampaign-icon.svg';
import AirtableIcon from 'images/airtable-icon.svg';
import CalendlyIcon from 'images/calendly-icon.svg';
import SalesforceIcon from 'images/salesforce-icon.svg';
import BrevoIcon from 'images/brevo-icon.svg';
import ElementorFormsIcon from 'images/elementor-forms-icon.svg';
import ContactForm7Icon from 'images/cf7-icon.svg';
import FacebookIcon from 'images/facebook-icon.svg';
import GravityFormsIcon from 'images/gravity-forms-icon.svg';
import ConstantContactIcon from 'images/constantContact-icon.svg';
import ConvertkitIcon from 'images/convertkit-icon.svg';
import DripIcon from 'images/drip-icon.svg';
import KlaviyoIcon from 'images/klaviyo-icon.svg';
import KlentyIcon from 'images/klenty-icon.svg';
import ApolloIcon from 'images/apollo-icon.svg';
import LemlistIcon from 'images/lemlist-icon.svg';
import MailerliteIcon from 'images/mailerLite-icon.svg';
import OrttoIcon from 'images/ortto-icon.svg';
import OutplayIcon from 'images/outplay-icon.svg';
import OutreachIcon from 'images/outreach-icon.svg';
import ReplyioIcon from 'images/replyio-icon.svg';
import SendGridIcon from 'images/sendgrid-icon.svg';
import GoogleIcon from '../images/google-icon-transparent.svg';
import LinkedinIcon from '../images/linkedin-icon.svg';

export default {
	components: {
		PipedriveIcon,
		CopperIcon,
		MailchimpIcon,
		OutfunnelIcon,
		HubspotIcon,
		WixIcon,
		ZendeskSellIcon,
		CsvFileIcon,
		PipedriveIconTransparent,
		HubspotIconTransparent,
		ActivecampaignIcon,
		AirtableIcon,
		CalendlyIcon,
		SalesforceIcon,
		BrevoIcon,
		ElementorFormsIcon,
		ContactForm7Icon,
		FacebookIcon,
		GravityFormsIcon,
		GoogleIcon,
		ConstantContactIcon,
		ConvertkitIcon,
		DripIcon,
		KlaviyoIcon,
		KlentyIcon,
		ApolloIcon,
		LemlistIcon,
		MailerliteIcon,
		OrttoIcon,
		OutplayIcon,
		OutreachIcon,
		ReplyioIcon,
		SendGridIcon,
		LinkedinIcon
	},

	props: {
		integration: {
			type: String,
			required: true
		},
		transparent: {
			type: Boolean,
			default: false
		}
	}
};

