export const CONFIGURE_VIEW = 'CONFIGURE_VIEW';
export const LIST_NAVIGATE = 'LIST_NAVIGATE';

export const OPTIONS_GET = 'OPTIONS_GET';
export const OPTIONS_REFRESH = 'OPTIONS_REFRESH';
export const OPTIONS_FIELD_MAPPINGS_GET = 'OPTIONS_FIELD_MAPPINGS_GET';

export const CONNECTION_GET = 'CONNECTION_GET';
export const CONNECTION_CREATE = 'CONNECTION_CREATE';
export const CONNECTION_DELETE = 'CONNECTION_DELETE';
export const CONNECTION_EDIT = 'CONNECTION_EDIT';
export const CONNECTION_ACTIVATE = 'CONNECTION_ACTIVATE';
export const CONNECTION_REQUEST_SYNC = 'CONNECTION_REQUEST_SYNC';
export const CONNECTION_SYNC_PROGRESS_UPDATE = 'CONNECTION_SYNC_PROGRESS_UPDATE';

export const RULE_CREATE = 'RULE_CREATE';
export const RULE_EDIT = 'RULE_EDIT';
export const RULE_DELETE = 'RULE_DELETE';

export const CONTACT_CREATION_RULES_GET = 'CONTACT_CREATION_RULES_GET';
export const CONTACT_CREATION_RULE_CREATE = 'CONTACT_CREATION_RULE_CREATE';
export const CONTACT_CREATION_RULE_EDIT = 'CONTACT_CREATION_RULE_EDIT';
export const CONTACT_CREATION_RULE_DELETE = 'CONTACT_CREATION_RULE_DELETE';

export const REVERSED_CONTACT_SYNC_RULE_EDIT = 'REVERSED_CONTACT_SYNC_RULE_EDIT';

export const CONTACT_SYNC_RULES_GET = 'CONTACT_SYNC_RULES_GET';
export const CONTACT_CREATION_RULE_OPTIONS_GET = 'CONTACT_CREATION_RULE_OPTIONS_GET';
export const FIELD_MAPPINGS_OPTIONS_GET = 'FIELD_MAPPINGS_OPTIONS_GET';
export const SEGMENT_FIELD_MAPPINGS_OPTIONS_GET = 'SEGMENT_FIELD_MAPPINGS_OPTIONS_GET';
export const SEGMENT_FIELD_MAPPINGS_GET = 'SEGMENT_FIELD_MAPPINGS_GET';
export const REVERSED_FIELD_MAPPINGS_GET = 'REVERSED_FIELD_MAPPINGS_GET';
export const FIELD_MAPPINGS_GET = 'FIELD_MAPPINGS_GET';
export const ENGAGEMENT_SEGMENT_FIELD_MAPPINGS_OPTIONS_GET = 'ENGAGEMENT_SEGMENT_FIELD_MAPPINGS_OPTIONS_GET';

export const MAPPING_CREATE = 'MAPPING_CREATE';
export const MAPPING_EDIT = 'MAPPING_EDIT';
export const MAPPING_DELETE = 'MAPPING_DELETE';

export const ENGAGEMENT_SYNC_RULES_GET = 'ENGAGEMENT_SYNC_RULES_GET';
export const ENGAGEMENT_SYNC_RULE_CREATE = 'ENGAGEMENT_SYNC_RULE_CREATE';
export const ENGAGEMENT_SYNC_RULE_DELETE = 'ENGAGEMENT_SYNC_RULE_DELETE';

export const ENGAGEMENT_SYNC_RULE_OPTIONS_GET = 'ENGAGEMENT_SYNC_RULE_OPTIONS_GET';
export const STATS_GET = 'STATS_GET';
export const FEED_GET = 'FEED_GET';

export const CONNECTION_STATUS_OPTIONS_GET = 'CONNECTION_STATUS_OPTIONS_GET';
export const CONNECTION_STATUS_PERIOD_EDIT = 'CONNECTION_STATUS_PERIOD_EDIT';

export const ENGAGEMENT_SYNC_UPGRADE_DIALOG_CLOSE = 'ENGAGEMENT_SYNC_UPGRADE_DIALOG_CLOSE';
export const ENGAGEMENT_SYNC_UPGRADE_DIALOG_OPEN = 'ENGAGEMENT_SYNC_UPGRADE_DIALOG_OPEN';

export const MAP_REQUIRED_FIELDS_DIALOG_CLOSE = 'MAP_REQUIRED_FIELDS_DIALOG_CLOSE';
export const MAP_REQUIRED_FIELDS_DIALOG_OPEN = 'MAP_REQUIRED_FIELDS_DIALOG_OPEN';

export const DIALOG_SYNC_RECENT_ENGAGEMENTS_OPEN = 'DIALOG_SYNC_RECENT_ENGAGEMENTS_OPEN';
export const DIALOG_SYNC_RECENT_ENGAGEMENTS_CLOSE = 'DIALOG_SYNC_RECENT_ENGAGEMENTS_CLOSE';
