import { debounce } from './debounce';
import { isUndefined } from './types';

const DATE_STRING_LENGTH = 10;

/**
 * Get features for popup window.
 * @param {Number} width
 * @param {Number} height
 */
function getFeatures(width, height) {
	const top = (window.outerHeight - height) / 2;
	const left = (window.outerWidth - width) / 2;

	return `width=${width},`
		+ `height=${height},`
		+ `top=${top},`
		+ `left=${left},`
		+ 'location=0,'
		+ 'titlebar=0';
}

const DEFAULT_WIDTH = 740;
const DEFAULT_HEIGHT = 680;

/**
 * Open a popup window with specified url.
 * @param {String} url - URL that the popup shall open.
 * @param {String} target - Open tab on specific window.
 * @param {Number} width - Width of the popup.
 * @param {Number} height - Height of the popup.
 */
function openPopupWindow(
	url,
	target = '',
	width = DEFAULT_WIDTH,
	height = DEFAULT_HEIGHT
) {
	window.open(url, target, getFeatures(width, height));
}

/**
 * Redirect to another website.
 * @param {String} url
 */
function redirectToUrl(url) {
	window.open(url, '_self');
}

/**
 * Post a message to target window.
 * @param {Object} data - Data to be sent to target window.
 * @param {Object} targetWindow - The window to receive the message.
 */
function postMessage(
	data,
	targetWindow = window.opener,
	origin = window.location.origin
) {
	targetWindow.postMessage(data, origin);
}

/**
 * @return {Boolean}
 */
function isPopupWindow() {
	return Boolean(window.opener);
}

/**
 * Reverse sort order.
 * @param {String} sortOrder "asc" or "desc".
 * @return {String}
 */
function reverseSortOrder(sortOrder) {
	let newSortOrder = 'asc';

	if (sortOrder === 'asc') {
		newSortOrder = 'desc';
	}

	return newSortOrder;
}

/**
 * Delay executing next instructions by specified number of seconds.
 * @param {Number} seconds - Number of seconds to delay.
 */
async function delay(seconds) {
	return new Promise(resolve => {
		window.setTimeout(resolve, seconds * 1000);
	});
}

/**
 * @return {String}
 */
function getCdnUrl() {
	const protocol = 'https:';
	const host = 'cdn.outfunnel.com';

	return `${protocol}//${host}`;
}

/**
 * @return {String}
 */
function getClientVersion() {
	// eslint-disable-next-line no-underscore-dangle
	return window.__outfunnel_client_version__;
}

/**
 * @return {String}
 */
function getServiceEnv() {
	return process.env.SERVICE_ENV;
}

/**
 * Set browser's window/tab title programmatically.
 * @param {String} title
 */
function setPageTitle(title) {
	window.document.title = title;
}

/**
 * Copy selected text to clipboard.
 */
function copySelectedText() {
	window.document.execCommand('copy');
}

async function copyTextToClipboard(text) {
	return navigator?.clipboard?.writeText(text);
}

/**
 * Retrieves a field from an object or from the options.
 * @param {Object} params
 * @param {String} params.field
 * @param {Object} params.options
 * @param {Object} params.object
 * @return {null|Object}
 */
const getValueWithFallback = ({ field, options, object }) => {
	if (!isUndefined(object[field])) {
		return object[field];
	}

	if (options[field] && !isUndefined(options[field].default)) {
		return options[field].default;
	}

	return null;
};

/**
 * Convert date to date string in YYYY-MM-DD format.
 * @param {Date} date
 * @return {String}
 */
const getDateString = date => date.toISOString().substring(0, DATE_STRING_LENGTH);

/**
 * Get tracking script.
 * @param {Object} params
 * @param {String} params.userId
 * @return {String}
 * */
const getTrackingScript = ({ userId }) => [
	'<script>',
	`window.OFID = "${userId}";`,
	'(function(){',
	'var script = document.createElement("script");',
	`var url = '${getCdnUrl()}/c.js?v='+ new Date().toISOString().substring(0,10);`,
	'script.setAttribute(\'src\', url);',
	'document.getElementsByTagName(\'head\')[0].appendChild(script);',
	'})();',
	'</script>'
].join('\n');

/**
 * Get of.isLoggedInAsUser from localStorage.
 * @return {Boolean}
 */
const isLoggedInAsUser = () => Boolean(localStorage.getItem('of.isLoggedInAsUser'));

export default {
	openPopupWindow,
	redirectToUrl,
	isPopupWindow,
	postMessage,
	reverseSortOrder,
	delay,
	getCdnUrl,
	getClientVersion,
	getServiceEnv,
	setPageTitle,
	copySelectedText,
	copyTextToClipboard,
	getValueWithFallback,
	getDateString,
	getTrackingScript,
	isLoggedInAsUser,
	debounce
};
