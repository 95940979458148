//
//
//
//
//
//
//

import { createNamespacedHelpers } from 'vuex';

import ROUTE_NAMES from 'router/route-names';
import Navigation from './Navigation.vue';

const userHelpers = createNamespacedHelpers('user');

const APP_CONNECTOR_LABEL = 'App connector';
const APP_CONNECTOR_ICON = 'mdi-transit-connection-variant';

export default {
	components: {
		Navigation
	},

	computed: {
		routes() {
			const routes = [];

			if (this.views?.navigation?.appConnector) {
				routes.push({
					name: this.locales?.navigation?.appConnector ?? APP_CONNECTOR_LABEL,
					icon: APP_CONNECTOR_ICON,
					route: { name: ROUTE_NAMES.CONNECTIONS }
				});
			}

			if (this.views?.navigation?.campaigns) {
				routes.push({
					name: this.locales?.navigation?.campaigns ?? 'Campaigns',
					icon: 'mdi-email-outline',
					route: { name: ROUTE_NAMES.CAMPAIGNS }
				});
			}

			if (this.views?.navigation?.contacts) {
				routes.push({
					name: this.locales?.navigation?.contacts ?? 'Contacts',
					icon: 'mdi-account-multiple-outline',
					route: { name: ROUTE_NAMES.CONTACTS }
				});
			}

			routes.push({
				name: this.locales?.navigation?.web ?? 'Web',
				icon: 'mdi-monitor-eye',
				route: { name: ROUTE_NAMES.WEB_TRACKING_IDENTIFIED_VISITORS }
			});

			if (this.views?.navigation?.leadScoring) {
				routes.push({
					name: this.locales?.navigation?.leadScoring ?? 'Lead scoring',
					icon: 'mdi-radar',
					route: { name: ROUTE_NAMES.LEAD_SCORING }
				});
			}

			routes.push({
				name: this.locales?.navigation?.help ?? 'Help',
				icon: 'mdi-help-circle-outline',
				isExternalLink: true,
				route: 'https://support.outfunnel.com/en/',
				placement: 'secondary'
			});

			routes.push({
				name: this.locales?.navigation?.account ?? 'Account',
				icon: 'mdi-cog-outline',
				route: this.accountRoute,
				placement: 'secondary'
			});

			return routes;
		},

		...userHelpers.mapGetters([
			'areAutomationsDisabled',
			'isCrmConnected',
			'locales',
			'isPipedriveConnected',
			'views',
			'isBillingVisible',
			'isIntegrationsVisible',
			'isConnectorEnabled',
			'isConnectorEnabledForPipedrive',
			'isConnectorLiteEnabled',
			'isDashboardEnabled'
		]),

		accountRoute() {
			if (this.isBillingVisible) {
				return { name: ROUTE_NAMES.ACCOUNT_BILLING };
			}

			if (this.isIntegrationsVisible) {
				return { name: ROUTE_NAMES.ACCOUNT_INTEGRATIONS };
			}

			return { name: ROUTE_NAMES.ACCOUNT_USAGE };
		},

		logoRoute() {
			if (!this.isConnectorLiteEnabled && this.isDashboardEnabled) {
				return { name: ROUTE_NAMES.DASHBOARD };
			}

			if (this.isConnectorEnabled && !this.isConnectorLiteEnabled) {
				return { name: ROUTE_NAMES.CONNECTIONS };
			}

			return { name: ROUTE_NAMES.CAMPAIGNS };
		}
	}
};

