export const CONNECTION_ID = 'CONNECTION_ID';
export const CONNECTION = 'CONNECTION';
export const CONNECTION_STATUS = 'CONNECTION_STATUS';
export const CONTACTS = 'CONTACTS';
export const CURRENT_PAGE = 'CURRENT_PAGE';
export const PAGINATION = 'PAGINATION';
export const DOWNLOAD_URL = 'DOWNLOAD_URL';
export const ERRORS = 'ERRORS';
export const SORT_BY_FIELD = 'SORT_BY_FIELD';
export const SORT_ORDER = 'SORT_ORDER';
export const CONTACT_STATUS = 'CONTACT_STATUS';
export const PRIMARY_RULE_STATUS = 'PRIMARY_RULE_STATUS';
export const SECONDARY_RULE_STATUS = 'SECONDARY_RULE_STATUS';
export const SEARCH_TERM = 'SEARCH_TERM';
export const INTERVAL_ID = 'INTERVAL_ID';
export const CONTACT_SYNC_RULES = 'CONTACT_SYNC_RULES';
