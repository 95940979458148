import ROUTE_NAMES from 'router/route-names';
import {
	setToken,
	setSignupPlan,
	getSignupPlan
} from 'utils/storage';
import router from 'router';
import { openOauthLoginWindow } from 'utils/oauth';
import logger from 'utils/logger';
import { getSignupIntents } from 'utils/tracking';
import { AuthProviders } from 'utils/constants';
import { getCaptchaToken } from 'utils/captcha';

import { WIX_INSTALL } from 'store/modules/moduleWixInstall/actionTypes';

import { api } from 'api';
import {
	NAME_SET_ERROR,
	EMAIL_SET_ERROR,
	PASSWORD_SET_ERROR,
	TERMS_SET_ERROR,
	SIGNUP_PLAN,
	SIGNUP_COUPON,
	OAUTH_SIGNUP_BUTTON_VISIBLE,
	GENERAL_ERRORS,
	TERMS_CONFIRMATION_DIALOG_VISIBLE
} from './mutationTypes';

import {
	GET_USER,
	USAGE_GET,
	ANALYTICS_INTEGRATION_ADDED,
	ANALYTICS_SIGNUP_IDENTIFY
} from '../user/actionTypes';

import {
	SUBSCRIPTION_GET
} from '../account/billing/actionTypes';

import {
	REGISTER_USER_WITH_CREDENTIALS,
	REGISTER_USER_WITH_OAUTH,
	START_OAUTH,
	STORE_SIGNUP_PLAN,
	STORE_SIGNUP_COUPON,
	GET_SIGNUP_PLAN,
	GET_INTENT,
	TERMS_CONFIRMATION_SHOW,
	TERMS_CONFIRMATION_HIDE,
	NAVIGATE_CONNECTIONS,
	NAVIGATE_HOME
} from './actionTypes';

import {
	LOGIN_ANALYTICS_IDENTIFY
} from '../login/actionTypes';

import { SET_TOKEN } from '../../mutationTypes';

const termsErrorMessage = 'You need to agree with Terms and Data Processing agreement to be able to create an Outfunnel account';

function parseErrors(errors, commit) {
	errors.forEach(errorObj => {
		if (errorObj.context === 'name') {
			commit(NAME_SET_ERROR, { error: errorObj.message });
		} else if (errorObj.context === 'email') {
			commit(EMAIL_SET_ERROR, { error: errorObj.message });
		} else if (errorObj.context === 'password') {
			commit(PASSWORD_SET_ERROR, { error: errorObj.message });
		}
	});
}

function routeUserToHome(user) {
	const isDashboardEnabled = user?.profile?.featureFlags?.enableDashboard;

	if (isDashboardEnabled) {
		router.push({
			name: ROUTE_NAMES.DASHBOARD
		});
	} else {
		router.push({
			name: ROUTE_NAMES.CONNECTIONS
		});
	}
}

export default {
	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[REGISTER_USER_WITH_CREDENTIALS]: async ({
		commit,
		dispatch,
		state,
		rootState
	}) => {
		let errorsFound = false;

		if (state.emailField.value.length === 0) {
			commit(EMAIL_SET_ERROR, { error: 'Enter your work email' });
			errorsFound = true;
		}

		if (!state.termsCheckbox.value) {
			commit(TERMS_SET_ERROR, termsErrorMessage);
			errorsFound = true;
		}

		if (!errorsFound) {
			const captchaToken = await getCaptchaToken('register');

			const registerFormData = {
				name: state.nameField.value,
				email: state.emailField.value,
				company: state.companyField.value,
				password: state.passwordField.value,
				tips: state.tipsCheckbox.value,
				signupPlan: state.signupPlan,
				signupPlanGroup: state.signupPlanGroup,
				signupPlanEvents: state.signupPlanEvents,
				signupPlanActiveContacts: state.signupPlanActiveContacts,
				captchaToken
			};

			if (state.signupCoupon) {
				registerFormData.signupCoupon = state.signupCoupon;
			}

			try {
				const token = await api.users.post(registerFormData);

				setToken(token);
				commit(SET_TOKEN, { token }, { root: true });

				await Promise.all([
					dispatch(`user/${GET_USER}`, null, { root: true }),
					dispatch(`account/billing/${SUBSCRIPTION_GET}`, null, { root: true })
				]);

				dispatch(`user/${ANALYTICS_SIGNUP_IDENTIFY}`, AuthProviders.EMAIL, { root: true });

				if (rootState.moduleWixInstall.wixToken) {
					dispatch(`moduleWixInstall/${WIX_INSTALL}`, null, { root: true });
				}

				routeUserToHome(rootState?.user);
			} catch (exception) {
				if (exception.errors) {
					parseErrors(exception.errors, commit);
					commit(GENERAL_ERRORS, exception.errors);
				} else {
					commit(NAME_SET_ERROR, { error: 'Something went wrong. Please try again.' });
					logger.error(exception);
				}
			}
		}
	},

	[REGISTER_USER_WITH_OAUTH]: async ({
		commit,
		dispatch,
		rootState
	}, {
		code,
		provider
	}) => {
		try {
			const { token, isNewUser, integrationAdded } = await api.auth.oauthCode.post({
				code,
				provider
			});

			setToken(token);

			commit(SET_TOKEN, { token }, { root: true });

			await Promise.all([
				dispatch(`user/${GET_USER}`, null, { root: true }),
				dispatch(`account/billing/${SUBSCRIPTION_GET}`, null, { root: true })
			]);

			if (isNewUser) {
				dispatch(`user/${ANALYTICS_SIGNUP_IDENTIFY}`, provider, { root: true });
			} else {
				dispatch(`login/${LOGIN_ANALYTICS_IDENTIFY}`, null, { root: true });
			}

			if (integrationAdded) {
				dispatch(`user/${ANALYTICS_INTEGRATION_ADDED}`, provider);
			}

			dispatch(`user/${USAGE_GET}`, null, { root: true });

			routeUserToHome(rootState?.user);
		} catch (exception) {
			if (exception.errors) {
				commit(GENERAL_ERRORS, exception.errors);
			} else {
				commit(GENERAL_ERRORS, [{ message: 'Something went wrong. Please try again.' }]);
			}
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[START_OAUTH]: async ({ state, commit }, { provider }) => {
		if (!state.termsCheckbox.value) {
			commit(TERMS_SET_ERROR, termsErrorMessage);
		} else {
			openOauthLoginWindow({ provider, action: 'register' });
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[GET_SIGNUP_PLAN]: ({ commit }) => {
		const signupPlan = getSignupPlan();

		if (signupPlan) {
			commit(SIGNUP_PLAN, signupPlan);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[STORE_SIGNUP_PLAN]: ({ commit }, signupPlan) => {
		setSignupPlan(signupPlan);
		commit(SIGNUP_PLAN, signupPlan);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[STORE_SIGNUP_COUPON]: ({ commit }, signupCoupon) => {
		commit(SIGNUP_COUPON, signupCoupon);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[GET_INTENT]: ({ commit }) => {
		const intents = getSignupIntents();

		if (intents.includes('copper')) {
			commit(OAUTH_SIGNUP_BUTTON_VISIBLE, false);
		}
	},

	[TERMS_CONFIRMATION_SHOW]: ({ commit }) => {
		commit(TERMS_CONFIRMATION_DIALOG_VISIBLE, true);
	},

	[TERMS_CONFIRMATION_HIDE]: ({ commit }) => {
		commit(TERMS_CONFIRMATION_DIALOG_VISIBLE, false);
	},

	[NAVIGATE_CONNECTIONS]: () => {
		router.push({
			name: ROUTE_NAMES.CONNECTIONS
		});
	},

	[NAVIGATE_HOME]: user => {
		const isDashboardEnabled = user?.featureFlags?.enableDashboard;

		if (isDashboardEnabled) {
			router.push({
				name: ROUTE_NAMES.DASHBOARD
			});
		}

		router.push({
			name: ROUTE_NAMES.CONNECTIONS
		});
	}
};
