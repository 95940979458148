export const SIGNUP_FORM_VISIBLE = 'SIGNUP_FORM_VISIBLE';
export const NAME_EDIT = 'NAME_EDIT';
export const NAME_SET_ERROR = 'NAME_SET_ERROR';

export const COMPANY_EDIT = 'COMPANY_EDIT';
export const COMPANY_SET_ERROR = 'COMPANY_SET_ERROR';

export const EMAIL_EDIT = 'EMAIL_EDIT';
export const EMAIL_SET_ERROR = 'EMAIL_SET_ERROR';

export const PASSWORD_EDIT = 'PASSWORD_EDIT';
export const PASSWORD_SET_ERROR = 'PASSWORD_SET_ERROR';

export const TERMS_EDIT = 'TERMS_EDIT';
export const TERMS_SET_ERROR = 'TERMS_SET_ERROR';

export const TIPS_EDIT = 'TIPS_EDIT';

export const RESET_STATE = 'RESET_STATE';
export const SIGNUP_PLAN = 'SIGNUP_PLAN';
export const SIGNUP_COUPON = 'SIGNUP_COUPON';
export const SIGNUP_PLAN_GROUP = 'SIGNUP_PLAN_GROUP';
export const SIGNUP_PLAN_EVENTS = 'SIGNUP_PLAN_EVENTS';
export const SIGNUP_PLAN_ACTIVE_CONTACTS = 'SIGNUP_PLAN_ACTIVE_CONTACTS';
export const OAUTH_SIGNUP_BUTTON_VISIBLE = 'OAUTH_SIGNUP_BUTTON_VISIBLE';
export const PIPEDRIVE_OAUTH_VISIBLE = 'PIPEDRIVE_OAUTH_VISIBLE';
export const HUBSPOT_OAUTH_VISIBLE = 'HUBSPOT_OAUTH_VISIBLE';
export const TERMS_CONFIRMATION_DIALOG_VISIBLE = 'TERMS_CONFIRMATION_DIALOG_VISIBLE';

export const GENERAL_ERRORS = 'GENERAL_ERRORS';
