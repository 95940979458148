//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import utils from 'utils';
import ROUTE_NAMES from 'router/route-names';
import OutfunnelLogo from '../images/outfunnel-logo.svg';
import NavigationLink from './NavigationLink.vue';

export default {
	components: {
		OutfunnelLogo,
		NavigationLink
	},
	props: {
		routes: {
			type: Array,
			required: true
		},
		logoRoute: {
			type: [Object, String],
			default: () => ({ name: ROUTE_NAMES.CONNECTIONS })
		}
	},
	computed: {
		mainRoutes() {
			return this.routes.filter(route => !route.placement);
		},

		secondaryRoutes() {
			return this.routes.filter(route => route.placement === 'secondary');
		},

		serviceEnv() {
			return utils.getServiceEnv();
		},

		isServiceEnvVisible() {
			return utils.getServiceEnv() !== 'live';
		}
	}
};

