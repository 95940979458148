import { capitalize } from 'utils/formatting';
import utils from 'utils';
import { Providers } from './constants';
import { getPipedriveOauthDialogUrl } from './pipedrive';
import { getMailchimpOauthDialogUrl } from './mailchimp';
import { getHubspotOauthDialogUrl, getHubspotMktOauthDialogUrl } from './hubspot';
import { getWixOauthDialogUrl } from './wix';
import { getFacebookOauthDialogUrl, getFacebookAdsOauthDialogUrl } from './facebook';
import { getCalendlyOauthDialogUrl } from './calendly';
import { getSalesforceOauthDialogUrl } from './salesforce';
import { getGoogleOauthDialogUrl } from './google';
import { getLinkedinOauthDialogUrl } from './linkedin';

/**
 * @param {Object} params
 * @param {String} params.provider OAuth service provider.
 * @param {String=} params.token OAuth flow token.
 * @param {String} params.action
 * @return {String}
 */
function getOauthUrl({ provider, token, action }) {
	if (provider === Providers.PIPEDRIVE) {
		return getPipedriveOauthDialogUrl(action);
	}

	if (provider === Providers.MAILCHIMP) {
		return getMailchimpOauthDialogUrl();
	}

	if (provider === Providers.HUBSPOT) {
		return getHubspotOauthDialogUrl(action);
	}

	if (provider === Providers.HUBSPOT_MARKETING) {
		return getHubspotMktOauthDialogUrl();
	}

	if (provider === Providers.WIX) {
		return getWixOauthDialogUrl(token);
	}

	if (provider === Providers.FACEBOOK) {
		return getFacebookOauthDialogUrl();
	}

	if (provider === Providers.FACEBOOK_ADS) {
		return getFacebookAdsOauthDialogUrl();
	}

	if (provider === Providers.CALENDLY) {
		return getCalendlyOauthDialogUrl();
	}

	if (provider === Providers.SALESFORCE) {
		return getSalesforceOauthDialogUrl();
	}

	if (provider === Providers.GOOGLE) {
		return getGoogleOauthDialogUrl(action);
	}

	if (provider === Providers.LINKEDIN) {
		return getLinkedinOauthDialogUrl(action);
	}
}

/**
 * @param {Object} params
 * @param {String} params.provider - OAuth service provider.
 * @param {String=} params.token - Optional token.
 * @param {String} params.action
 */
export function openOauthLoginWindow({ provider, token, action }) {
	const oauthLoginUrl = getOauthUrl({ provider, token, action });

	utils.openPopupWindow(oauthLoginUrl);
}

/**
 * Send authorization code from OAuth login window to parent window.
 * @param {Object} params OAuth login options.
 * @param {String} params.provider - OAuth service provider.
 * @param {String} params.code - OAuth authorization code.
 * @param {Boolean} params.close - Whether popup window should be closed automatically.
 * @param {String} params.state
 */
export function postOauthCodeToMainWindow({
	provider,
	code,
	close = true,
	state = ''
}) {
	utils.postMessage({
		type: 'oauth',
		provider,
		code,
		state
	});

	if (close) {
		window.close();
	}
}

/**
 * @param {Object} params
 * @param {Object[]} params.exception  - Exception object array.
 * @param {String} params.engagementEventSource
 * @return {{ error: String, message: String}}
 */
export function getInvalidOauthTokenError({ exception, engagementEventSource }) {
	const invalidOauthTokenError = exception.errors.find(err => err.error === 'invalid_oauth_token');

	if (invalidOauthTokenError) {
		return {
			...invalidOauthTokenError,
			message: `${capitalize(engagementEventSource)} access token has been revoked. <a href="/account/integrations">Please reconnect ${capitalize(engagementEventSource)}</a>`
		};
	}

	return null;
}
