import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const options = {
	theme: {
		themes: {
			light: {
				primary: '#00bbff',
				warning: '#f75b3a',
				infobox: '#0096cc',
				info: '#5479bd',
				success: '#43A047',
				dark: '#464C5B',
				premium: '#ECB22E'
			}
		}
	},
	icons: {
		iconfont: 'mdi'
	}
};

const vuetify = new Vuetify(options);

export {
	vuetify
};
