import logger from 'utils/logger';
import { api } from 'api';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	ContactSyncDirections,
	EmailEngagementSources
} from 'utils/constants';
import { ConnectionStatuses } from 'utils/constants/connections';
import { debounce } from 'utils/debounce';
import ROUTE_NAMES from 'router/route-names';
import router from 'router';
import {
	CONNECTION,
	CONTACT_SYNC_RULES,
	PRIMARY_RULE_STATUS,
	SECONDARY_RULE_STATUS,
	CONTACTS,
	CURRENT_PAGE,
	ERRORS,
	PAGINATION,
	DOWNLOAD_URL,
	SORT_ORDER,
	SORT_BY_FIELD,
	SEARCH_TERM,
	INTERVAL_ID
} from './mutationTypes';

import {
	LIST_NAVIGATE,
	CONNECTION_GET,
	CONTACT_SYNC_RULES_GET,
	CONNECTION_REQUEST_SYNC,
	CONNECTION_CONTACTS_GET,
	CONNECTION_STATUS_GET,
	CURRENT_PAGE_SET,
	SORTING_CHANGE,
	SEARCH,
	PAGE_SELECT,
	MOUNTED,
	DESTROYED,
	NAVIGATE_EDIT
} from './actionTypes';
import {
	SortOrders
} from './constants';
import { trackConnectionManualSync } from '../helpers';

const searchDelay = 200;

const DATA_FETCH_INTERVAL = 1000;

export const actions = {
	[LIST_NAVIGATE]: () => {
		router.push({ name: ROUTE_NAMES.CONNECTIONS });
	},

	[CONNECTION_GET]: async ({
		commit,
		state,
		rootState: { token }
	}) => {
		try {
			const connection = await api.connections.get({ id: state.connectionId, token });

			commit(CONNECTION, connection);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACT_SYNC_RULES_GET]: async ({
		commit,
		state,
		rootState: { token }
	}) => {
		try {
			const contactSyncRules = await api.connections.contactSyncRules.get({
				connectionId: state.connectionId,
				token
			});

			commit(CONTACT_SYNC_RULES, contactSyncRules);
		} catch (exception) {
			if (exception.errors) {
				commit(ERRORS, exception.errors);

				return;
			}

			logger.error(exception);
		}
	},

	[CONNECTION_REQUEST_SYNC]: async ({
		rootState: { token },
		state,
		commit,
		dispatch
	}) => {
		const connectionData = {
			status: ConnectionStatuses.SYNCING
		};

		try {
			await api.connections.patch({
				connectionId: state.connection._id,
				token,
				connectionData
			});

			await dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sync started and will complete within 5 minutes',
				{ root: true }
			);

			trackConnectionManualSync(state.connection, 'status');
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}
		}
	},

	[CONNECTION_CONTACTS_GET]: debounce(async ({
		rootState: { token },
		state,
		commit
	}, {
		status,
		term,
		page
	} = {}) => {
		const connectionId = state.connection?._id ?? state.connectionId;

		const contactStatus = status ?? state.contactStatus;
		const searchTerm = term ?? state.searchTerm;
		const currentPage = page ?? state.pagination.currentPage;

		try {
			const { data, pagination, downloadUrl } = await api.connections.contacts.get({
				connectionId,
				status: contactStatus,
				term: searchTerm,
				page: currentPage,
				sortBy: state.sortByField,
				sortOrder: state.sortOrder,
				token
			});

			if (state.pagination.currentPage !== pagination.currentPage) {
				return;
			}

			commit(CONTACTS, data);
			commit(PAGINATION, pagination);
			commit(DOWNLOAD_URL, downloadUrl);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}, searchDelay),

	[CONNECTION_STATUS_GET]: async ({
		rootState: { token },
		state,
		commit
	}) => {
		const connectionId = state.connection?._id ?? state.connectionId;

		const isMarketingToCRM = (
			state.connection?.contactSyncDirection === ContactSyncDirections.MARKETING_TO_CRM
		);

		let primaryRuleContactSource,
			primaryEngagementSource;

		const engagementEventSource = state.connection?.engagementEventSource;
		const contactSource = state.connection?.contactSource;

		if (Object.values(EmailEngagementSources).includes(engagementEventSource)) {
			primaryRuleContactSource = (
				isMarketingToCRM ? engagementEventSource : contactSource
			);

			primaryEngagementSource = (
				isMarketingToCRM ? contactSource : engagementEventSource
			);
		}

		try {
			const primaryRuleStatusPromise = api.connections.status.get({
				connectionId,
				source: primaryRuleContactSource,
				destination: primaryEngagementSource,
				token
			});

			const secondaryRuleStatusPromise = api.connections.status.get({
				connectionId,
				source: state.connection?.engagementEventSource,
				destination: state.connection?.contactSource,
				token
			});

			const { data: primaryRuleStatus } = await primaryRuleStatusPromise;
			const { data: secondaryRuleStatus } = await secondaryRuleStatusPromise;

			commit(PRIMARY_RULE_STATUS, primaryRuleStatus);
			commit(SECONDARY_RULE_STATUS, secondaryRuleStatus);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CURRENT_PAGE_SET]: ({ commit }, page) => commit(CURRENT_PAGE, page),

	[SORTING_CHANGE]: ({ commit, state }, sortByField) => {
		if (sortByField === state.sortByField) {
			const newSortOrder = state.sortOrder === SortOrders.ASC ? SortOrders.DESC : SortOrders.ASC;

			commit(SORT_ORDER, newSortOrder);

			return;
		}

		commit(SORT_BY_FIELD, sortByField);
	},

	[SEARCH]: ({ commit, dispatch }, searchTerm) => {
		commit(SEARCH_TERM, searchTerm);
		dispatch(CONNECTION_CONTACTS_GET);
	},

	[PAGE_SELECT]: ({ commit, dispatch }, page) => {
		commit(CURRENT_PAGE, page);
		dispatch(CONNECTION_CONTACTS_GET);
	},

	[MOUNTED]: ({ commit, dispatch }) => {
		const intervalId = setInterval(() => {
			dispatch(CONNECTION_CONTACTS_GET);
			dispatch(CONNECTION_STATUS_GET);
		}, DATA_FETCH_INTERVAL);

		commit(INTERVAL_ID, intervalId);
	},

	[DESTROYED]: ({ state }) => {
		clearInterval(state.intervalId);
	},

	[NAVIGATE_EDIT]: ({ state }) => {
		router.push({
			name: ROUTE_NAMES.CONNECTIONS_EDIT,
			params: {
				id: state.connection._id
			}
		});
	}
};
