import { isEmail } from 'validator';
import ROUTE_NAMES from 'router/route-names';
import { api } from 'api';
import router from 'router';
import { openOauthLoginWindow } from 'utils/oauth';
import logger from 'utils/logger';
import { getCaptchaToken } from 'utils/captcha';
import {
	setToken,
	setIsLoggedInAsUser
} from 'utils/storage';
import { WIX_INSTALL } from 'store/modules/moduleWixInstall/actionTypes';
import {
	SET_TOKEN,
	SET_LOGGED_IN_AS_USER
} from '../../mutationTypes';
import {
	LOGIN_USER_WITH_CREDENTIALS,
	LOGIN_USER_WITH_OAUTH,
	START_OAUTH,
	LOGIN_USER_WITH_TOKEN,
	LOGIN_ANALYTICS_IDENTIFY
} from './actionTypes';
import {
	GET_USER,
	USAGE_GET,
	ANALYTICS_IDENTIFY,
	ANALYTICS_INTEGRATION_ADDED,
	ANALYTICS_SIGNUP_IDENTIFY
} from '../user/actionTypes';
import { SUBSCRIPTION_GET } from '../account/billing/actionTypes';
import {
	IN_PROGRESS,
	SUCCESS,
	FAILED,
	ERRORS,
	FIELD_ERROR
} from './mutationTypes';

/**
 *
 * @param {Object} rootState
 * @return {String}
 */
const getRouteName = rootState => {
	const isConnectorLiteEnabled = rootState?.user?.profile?.featureFlags?.enableConnectorLite;

	if (isConnectorLiteEnabled) {
		return ROUTE_NAMES.CAMPAIGNS;
	}

	const isDashboardEnabled = rootState?.user?.profile?.featureFlags?.enableDashboard;

	if (isDashboardEnabled) {
		return ROUTE_NAMES.DASHBOARD;
	}

	return ROUTE_NAMES.CONNECTIONS;
};

export default {
	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[LOGIN_USER_WITH_CREDENTIALS]: async ({
		commit,
		dispatch,
		state,
		rootState
	}) => {
		const isInProgress = state.loginStatus === 'in_progress';
		let hasError = false;

		if (!isEmail(state.emailField.value)) {
			hasError = true;
			commit(FIELD_ERROR, { context: 'email', message: 'Enter valid email address' });
		}

		if (!state.passwordField.value.length) {
			hasError = true;

			return commit(FIELD_ERROR, { context: 'password', message: 'Enter password' });
		}

		if (hasError || isInProgress) {
			return true;
		}

		try {
			commit(IN_PROGRESS);

			const captchaToken = await getCaptchaToken('login');

			const token = await api.auth.credentials.post({
				email: state.emailField.value,
				password: state.passwordField.value,
				captchaToken
			});

			setToken(token);
			commit(SUCCESS);
			commit(SET_TOKEN, { token }, { root: true });

			await Promise.all([
				dispatch(`user/${GET_USER}`, null, { root: true }),
				dispatch(`account/billing/${SUBSCRIPTION_GET}`, null, { root: true })
			]);

			dispatch(LOGIN_ANALYTICS_IDENTIFY);
			dispatch(`user/${USAGE_GET}`, null, { root: true });

			if (rootState.moduleWixInstall.wixToken) {
				dispatch(`moduleWixInstall/${WIX_INSTALL}`, null, { root: true });
			}

			router.push({
				name: getRouteName(rootState)
			});
		} catch (exception) {
			if (exception.errors) {
				commit(ERRORS, exception.errors);
			} else {
				commit(ERRORS, [{ message: 'Something went wrong. Please try again.' }]);
				logger.error(exception);
			}

			commit(FAILED, exception.name);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[LOGIN_ANALYTICS_IDENTIFY]: async ({
		dispatch,
		rootState: { user: { profile } }
	}) => {
		const { created } = profile;

		dispatch(`user/${ANALYTICS_IDENTIFY}`, { userCreatedAt: created }, { root: true });
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[START_OAUTH]: async (
		// eslint-disable-next-line no-empty-pattern
		{ },
		{ provider }
	) => {
		openOauthLoginWindow({ provider, action: 'login' });
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[LOGIN_USER_WITH_OAUTH]: async ({
		commit,
		dispatch,
		rootState
	}, { code, provider }) => {
		commit(IN_PROGRESS);
		const signupCoupon = rootState?.register?.signupCoupon;

		try {
			const { token, isNewUser, integrationAdded } = await api.auth.oauthCode.post({
				code,
				provider,
				signupCoupon
			});

			setToken(token);

			commit(SUCCESS);
			commit(SET_TOKEN, { token }, { root: true });

			await Promise.all([
				dispatch(`user/${GET_USER}`, null, { root: true }),
				dispatch(`account/billing/${SUBSCRIPTION_GET}`, null, { root: true })
			]);

			if (isNewUser) {
				dispatch(`user/${ANALYTICS_SIGNUP_IDENTIFY}`, provider, { root: true });
			} else {
				dispatch(LOGIN_ANALYTICS_IDENTIFY);
			}

			if (integrationAdded) {
				dispatch(`user/${ANALYTICS_INTEGRATION_ADDED}`, provider, { root: true });
			}

			dispatch(`user/${USAGE_GET}`, null, { root: true });

			router.push({
				name: getRouteName(rootState)
			});
		} catch (exception) {
			if (exception.errors) {
				commit(ERRORS, exception.errors);
			} else {
				commit(ERRORS, [{ message: 'Something went wrong.' }]);
				logger.error(exception);
			}

			commit(FAILED, exception.name);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[LOGIN_USER_WITH_TOKEN]: async ({
		commit,
		dispatch,
		rootState
	}, { email, passwordlessToken }) => {
		commit(IN_PROGRESS);

		try {
			const {
				token,
				requestedBy
			} = await api.auth.passwordlessVerify.post(email, passwordlessToken);

			setToken(token);

			commit(SUCCESS);
			commit(SET_TOKEN, { token }, { root: true });

			if (requestedBy) {
				setIsLoggedInAsUser(true);
				commit(SET_LOGGED_IN_AS_USER, true, { root: true });
			}

			dispatch(`user/${GET_USER}`, null, { root: true });
			dispatch(`user/${USAGE_GET}`, null, { root: true });
			dispatch(`account/billing/${SUBSCRIPTION_GET}`, null, { root: true });

			router.push({
				name: getRouteName(rootState)
			});
		} catch (exception) {
			if (exception.errors) {
				commit(ERRORS, exception.errors);
			} else {
				commit(ERRORS, [{ message: 'Something went wrong.' }]);
				logger.error(exception);
			}

			commit(FAILED, exception.name);
		}
	}
};
