//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ROUTE_NAMES from 'router/route-names';
import { createNamespacedHelpers } from 'vuex';

const userHelpers = createNamespacedHelpers('user');

export default {
	props: {
		isFieldsVisible: {
			type: Boolean,
			default: false
		},
		isUsageVisible: {
			type: Boolean,
			default: false
		},
		isIntegrationsVisible: {
			type: Boolean,
			default: true
		},
		isBillingVisible: {
			type: Boolean,
			default: true
		},
		isEmailEngagementSettingsSupported: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		usageRoute: { name: ROUTE_NAMES.ACCOUNT_USAGE },
		profileRoute: { name: ROUTE_NAMES.ACCOUNT_PROFILE },
		billingRoute: { name: ROUTE_NAMES.ACCOUNT_BILLING },
		integrationsRoute: { name: ROUTE_NAMES.ACCOUNT_INTEGRATIONS },
		fieldsRoute: { name: ROUTE_NAMES.ACCOUNT_FIELDS },
		emailSettingsRoute: { name: ROUTE_NAMES.ACCOUNT_EMAIL_SETTINGS },
		emailSettingsSendingScheduleRoute: { name: ROUTE_NAMES.ACCOUNT_EMAIL_SETTINGS },
		emailSettingsReplyTrackingRoute: { name: ROUTE_NAMES.ACCOUNT_EMAIL_SETTINGS_REPLY_TRACKING },
		verifiedDomainsRoute: { name: ROUTE_NAMES.ACCOUNT_EMAIL_SETTINGS_DOMAINS },
		emailFooterRoute: { name: ROUTE_NAMES.ACCOUNT_EMAIL_SETTINGS_FOOTER },
		emailHealthRoute: { name: ROUTE_NAMES.ACCOUNT_EMAIL_SETTINGS_EMAIL_HEALTH },
		engagementRecording: { name: ROUTE_NAMES.ACCOUNT_EMAIL_SETTINGS_ENGAGEMENT_RECORDING }
	}),
	computed: {
		...userHelpers.mapGetters([
			'views',
			'areSequenceCampaignsEnabled'
		])
	}
};

