//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { getPlanName } from 'utils/billing/helpers';
import AccountBillingDowngradeIllustration from '../images/account-billing-downgrade-illustration.svg';

/**
 * @constant {String}
 */
const EVENT_CLOSE = 'close';
/**
 * @constant {String}
 */
const EVENT_DOWNGRADE_PLAN = 'downgrade:plan';

const ACTIVE_CONTACT_PLAN_GROUPS = ['Basic', 'Professional'];

export default {
	components: {
		AccountBillingDowngradeIllustration
	},
	props: {
		isVisible: {
			type: Boolean,
			required: false,
			default: false
		},
		mainContent: {
			type: String,
			required: true,
			default: ''
		},
		secondaryContent: {
			type: String,
			required: false,
			default: ''
		},
		currentPlan: {
			type: Object,
			required: false,
			default:
				() => ({
					name: '',
					_id: 1
				})
		},
		selectedPlanToDowngrade: {
			type: Object,
			required: false,
			default:
				() => ({
					name: '',
					_id: 2
				})
		}
	},

	data: () => ({
		learnMoreLink: 'https://outfunnel.com/pricing/'
	}),

	computed: {
		newPlanName() {
			return getPlanName(this.selectedPlanToDowngrade);
		},
		isCurrentAndDowngradePlanSameGroup() {
			return this.currentPlan?.group === this.selectedPlanToDowngrade?.group;
		},

		isActiveContactsPricingPlanDowngrade() {
			return ACTIVE_CONTACT_PLAN_GROUPS.includes(this.selectedPlanToDowngrade?.group);
		},

		decreaseTitle() {
			if (this.isActiveContactsPricingPlanDowngrade) {
				return 'Downgrading will decrease the number of <br> Active Contacts';
			}

			return 'Downgrading will decrease your event limits';
		},

		decreaseBody() {
			if (this.isActiveContactsPricingPlanDowngrade) {
				return `Active contacts will decrease from  ${this.currentPlan?.active_contacts}
				to ${this.selectedPlanToDowngrade?.active_contacts} per month.
	Changes go into effect on subscription renewal.`;
			}

			return `Event limits will decrease from ${this.currentPlan?.events_count}
				to ${this.selectedPlanToDowngrade?.events_count} events/month.
	Changes go into effect on subscription renewal.`;
		}

	},

	methods: {
		onClose() {
			this.$emit(EVENT_CLOSE);
		},
		onDowngradePlan() {
			if (this.selectedPlanToDowngrade?._id) {
				this.$emit(EVENT_DOWNGRADE_PLAN, this.selectedPlanToDowngrade._id);
			}
		}

	}
};

