// eslint-disable-next-line you-dont-need-lodash-underscore/throttle
import throttle from 'lodash/throttle';

import { api } from 'api';
import ROUTE_NAMES from 'router/route-names';
import logger from 'utils/logger';
import router from 'router';

import {
	GET_USER
} from 'store/modules/user/actionTypes';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	PERSONS,
	SEARCH,
	PAGINATION,
	GENERAL_ERRORS,
	DIALOG_CONTACTS_IMPORT_VISIBILITY,
	DIALOG_CONTACTS_IMPORT_LOADING,
	DIALOG_CONTACTS_IMPORT_RESET,
	DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR,
	SEGMENT_ID,
	SEGMENTS
} from './mutationTypes';

import {
	CONTACTS_NAVIGATE,
	PERSONS_ALL,
	PERSONS_SEGMENT,
	PERSONS_GET,
	PERSONS_SEARCH,
	PROFILE_VIEW,
	DIALOG_CONTACTS_IMPORT_SHOW,
	DIALOG_CONTACTS_IMPORT_CLOSE,
	DIALOG_CONTACTS_IMPORT_CONFIRM,
	SEGMENTS_GET,
	SEGMENT_CREATE,
	SEGMENT_EDIT,
	SEGMENT_DELETE
} from './actionTypes';

const PERSONS_PER_PAGE = 20;

/**
 *@constant {Number}
 */
const SEARCH_INTERVAL = 500;

export default {
	[CONTACTS_NAVIGATE]({
		state: {
			segmentId,
			search
		}
	}) {
		if (segmentId) {
			if (router.currentRoute.query.segment === segmentId) {
				return;
			}

			router.push({
				name: ROUTE_NAMES.CONTACTS,
				query: {
					segment: segmentId
				}
			});
		} else if (search) {
			if (router.currentRoute.query.search === search) {
				return;
			}

			router.push({
				name: ROUTE_NAMES.CONTACTS,
				query: {
					search
				}
			});
		} else {
			router.push({
				name: ROUTE_NAMES.CONTACTS
			});
		}
	},

	[PERSONS_ALL]({ commit, dispatch }) {
		commit(SEGMENT_ID, '');
		commit(SEARCH, '');
		dispatch(PERSONS_GET);
	},

	[PERSONS_SEGMENT]({ commit, dispatch }, segmentId) {
		commit(SEARCH, '');
		commit(SEGMENT_ID, segmentId);
		dispatch(PERSONS_GET);
	},

	async [PERSONS_GET]({
		commit,
		state: {
			segmentId,
			search
		},
		rootState: { token }
	}, page = 1) {
		try {
			const { data, pagination } = await api.persons.get({
				token,
				limit: PERSONS_PER_PAGE,
				page,
				segmentId,
				search
			});

			commit(PERSONS, data);
			commit(PAGINATION, pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[PERSONS_SEARCH]: throttle(async ({
		commit,
		state: { pagination: currentPagination },
		rootState: { token }
	}, search) => {
		commit(SEGMENT_ID, '');
		commit(SEARCH, search);

		try {
			const { data, pagination } = await api.persons.get({
				token,
				limit: PERSONS_PER_PAGE,
				page: currentPagination.currentPage,
				search
			});

			commit(PERSONS, data);
			commit(PAGINATION, pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}, SEARCH_INTERVAL, { leading: true }),

	[PROFILE_VIEW](context, personId) {
		router.push(`/contacts/${personId}`);
	},

	/**
	 * Show contacts import dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_CONTACTS_IMPORT_SHOW]({ commit }) {
		commit(DIALOG_CONTACTS_IMPORT_VISIBILITY, true);
	},

	/**
	 * Close contacts import dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_CONTACTS_IMPORT_CLOSE]({ commit }) {
		commit(DIALOG_CONTACTS_IMPORT_RESET);
	},

	/**
	 * Confirm and start contacts import.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_CONTACTS_IMPORT_CONFIRM]({
		commit,
		dispatch,
		rootGetters,
		rootState: { token },
		state: {
			selectedFile, segmentName, isOptInConfirmed, delimiter
		}
	}) {
		if (!isOptInConfirmed) {
			return commit(DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR, true);
		}

		try {
			commit(DIALOG_CONTACTS_IMPORT_LOADING, true);
			const {
				data: { segmentId } = { segmentId: null },
				errors
			} = await api.persons.imports.post(token, { file: selectedFile, segmentName, delimiter });

			if (errors) {
				commit(DIALOG_CONTACTS_IMPORT_LOADING, false);
				commit(DIALOG_CONTACTS_IMPORT_RESET);

				return commit(GENERAL_ERRORS, errors);
			}

			commit(SEGMENT_ID, segmentId);

			const isOutfunnelDatabaseConnected = rootGetters['user/isOutfunnelDatabaseConnected'];

			if (!isOutfunnelDatabaseConnected) {
				dispatch(`user/${GET_USER}`, null, { root: true });
			}

			dispatch(SEGMENTS_GET);
			dispatch(PERSONS_GET);
			dispatch(DIALOG_CONTACTS_IMPORT_CLOSE);
			dispatch(CONTACTS_NAVIGATE);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [SEGMENTS_GET]({
		commit,
		rootState: { token }
	}) {
		try {
			const segments = await api.segments.get(token);

			commit(SEGMENTS, segments);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SEGMENT_CREATE]() {
		router.push({ name: ROUTE_NAMES.SEGMENTS_CREATE });
	},

	[SEGMENT_EDIT](context, segmentId) {
		router.push({
			name: ROUTE_NAMES.SEGMENTS_EDIT,
			params: {
				id: segmentId
			}
		});
	},

	async [SEGMENT_DELETE]({
		rootState: { token },
		commit,
		dispatch
	}, segmentId) {
		commit(GENERAL_ERRORS, []);

		try {
			await api.segments.delete(token, segmentId);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Segment deleted',
				{ root: true }
			);

			dispatch(SEGMENTS_GET);
			dispatch(PERSONS_ALL);
			dispatch(CONTACTS_NAVIGATE);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
