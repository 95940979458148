//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_SAVE = 'save';
const EVENT_CLOSE = 'close';
const EVENT_CHANGE_AUTH_DATA = 'change:auth-data';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		authData: {
			type: Object,
			default: () => ({})
		},
		errors: {
			type: Array,
			default: () => ([])
		}
	},
	data: () => ({
		firstField: {
			id: 'apiKey',
			label: 'Private API key'
		},
		apiKeysLink: 'https://www.klaviyo.com/settings/account/api-keys',
		errorsToShow: {
			KLAVIYO_API_PERMISSION_DENIED: {
				error: 'klaviyo_api_permission_denied',
				message: `Your Klaviyo Private API Key does not have the required scopes.
				To fix, <a href="https://www.klaviyo.com/settings/account/api-keys" target="_blank">create a new
				Private API Key in Klaviyo</a> with full access for every API scope.
				Then insert the new Private API key and click "Connect". 
				Reach out to support if you require assistance.`,
				context: 'klaviyo'
			}
		}
	}),
	methods: {
		onSave() {
			this.$emit(EVENT_SAVE);
		},

		onClose() {
			this.$emit(EVENT_CLOSE);
		},

		onChangeAuthData(authData) {
			this.$emit(EVENT_CHANGE_AUTH_DATA, authData);
		},

		getErrorsToShow() {
			return this.errors.map(error => {
				if (error?.error === this.errorsToShow.KLAVIYO_API_PERMISSION_DENIED.error) {
					return this.errorsToShow.KLAVIYO_API_PERMISSION_DENIED;
				}

				return error;
			});
		}
	}
};

