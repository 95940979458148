//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: {
		isIntegrationConnected: {
			type: Boolean,
			default: false
		},
		isConnectButtonDisabled: {
			type: Boolean,
			default: false
		},
		reconnectButtonText: {
			type: String,
			default: 'Reconnect'
		}
	},
	methods: {
		startIntegrationConnect() {
			this.$emit('start-integration-connect');
		},
		disconnectIntegration() {
			this.$emit('disconnect-integration');
		}
	}
};
