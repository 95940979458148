import { options } from './options';
import { contacts } from './contacts';
import { contactSyncRules } from './contact-sync-rules';
import { contactCreationRules } from './contact-creation-rules';
import { fieldMappings } from './field-mappings';
import { engagementSyncRules } from './engagement-sync-rules';
import { stats } from './stats';
import { status } from './status';
import { feed } from './feed';
import { connectionStatus } from './connection-status';
import { httpGet } from './httpGet';
import { httpPost } from './httpPost';
import { httpPatch } from './httpPatch';
import { httpDelete } from './httpDelete';

export const connections = {
	options,
	contacts,
	contactSyncRules,
	contactCreationRules,
	fieldMappings,
	engagementSyncRules,
	stats,
	status,
	feed,
	connectionStatus,
	get: httpGet,
	post: httpPost,
	patch: httpPatch,
	delete: httpDelete
};
