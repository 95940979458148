/** Sign up to Zendesk Sell. */
export const ZENDESK_SIGNUP = 'ZENDESK_SIGNUP';

/** Show Contacts Import Dialog. */
export const DIALOG_CONTACTS_IMPORT_SHOW = 'DIALOG_CONTACTS_IMPORT_SHOW';

/** Close Contacts Import Dialog. */
export const DIALOG_CONTACTS_IMPORT_CLOSE = 'DIALOG_CONTACTS_IMPORT_CLOSE';

/** Confirm Contacts Import. */
export const DIALOG_CONTACTS_IMPORT_CONFIRM = 'DIALOG_CONTACTS_IMPORT_CONFIRM';

/** Integration sign up success. */
export const INTEGRATION_SIGN_UP_SUCCESS = 'INTEGRATION_SIGN_UP_SUCCESS';

export const OAUTH_CONNECT_START = 'OAUTH_CONNECT_START';
export const OAUTH_CONNECT = 'OAUTH_CONNECT';
export const OAUTH_CONNECT_SUCCESS = 'OAUTH_CONNECT_SUCCESS';

export const WIX_CONNECT_START = 'WIX_CONNECT_START';

export const APP_AUTH_DIALOG_HIDE = 'APP_AUTH_DIALOG_HIDE';
export const APP_AUTH_DIALOG_SHOW = 'APP_AUTH_DIALOG_SHOW';
export const APP_AUTH_DIALOG_SAVE = 'APP_AUTH_DIALOG_SAVE';
export const APP_AUTH_DIALOG_RESET = 'APP_AUTH_DIALOG_RESET';

export const WP_PLUGIN_AUTH_DIALOG_CONFIRM = 'WP_PLUGIN_AUTH_DIALOG_CONFIRM';
export const WP_PLUGIN_AUTH_DIALOG_SHOW = 'WP_PLUGIN_AUTH_DIALOG_SHOW';
export const WP_PLUGIN_AUTH_DIALOG_HIDE = 'WP_PLUGIN_AUTH_DIALOG_HIDE';
export const WP_PLUGIN_AUTH_DIALOG_RESET = 'WP_PLUGIN_AUTH_DIALOG_RESET';
export const VALUE_COPY = 'VALUE_COPY';

export const DISCONNECT_INTEGRATION = 'DISCONNECT_INTEGRATION';
