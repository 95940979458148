/**
 * @type {{
 * PIPEDRIVE: String,
 * MAILCHIMP: String,
 * HUBSPOT: String,
 * WIX: String,
 * FACEBOOK: String,
 * CALENDLY: String,
 * SALESFORCE: String,
 * GOOGLE: String,
 * FACEBOOK_ADS: String,
 * LINKEDIN: String
 * }}
 */
export const Providers = Object.freeze({
	PIPEDRIVE: 'pipedrive',
	MAILCHIMP: 'mailchimp',
	HUBSPOT: 'hubspot',
	HUBSPOT_MARKETING: 'hubspot-marketing',
	WIX: 'wix',
	FACEBOOK: 'facebook',
	CALENDLY: 'calendly',
	SALESFORCE: 'salesforce',
	GOOGLE: 'google',
	FACEBOOK_ADS: 'facebook-ads',
	LINKEDIN: 'linkedin'
});
