import {
	APPS,
	CONNECTIONS,
	ERRORS,
	UPGRADE_DIALOG_VISIBILITY,
	CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY,
	ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY,
	SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY,
	SPLIT_ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY,
	ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY,
	OPTIONS,
	CONTACT_CREATION_RULES
} from './mutationTypes';

export const mutations = {
	[APPS](state, apps) {
		state.apps = apps;
	},

	[CONNECTIONS](state, connections) {
		state.connections = connections;
	},

	[ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[UPGRADE_DIALOG_VISIBILITY](state, isVisisble) {
		state.isUpgradeDialogOpen = isVisisble;
	},

	[CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY](state, isVisisble) {
		state.isContactSyncConfirmationDialogOpen = isVisisble;
	},

	[ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY](state, isVisisble) {
		state.isEngagementSyncConfirmationDialogOpen = isVisisble;
	},

	[SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY](state, isVisisble) {
		state.isSplitContactSyncConfirmationDialogOpen = isVisisble;
	},

	[SPLIT_ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY](state, isVisisble) {
		state.isSplitEngagementSyncConfirmationDialogOpen = isVisisble;
	},

	[ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY](state, isVisisble) {
		state.isEngagementSyncUpgradeDialogOpen = isVisisble;
	},

	[OPTIONS](state, options) {
		state.options = options;
	},

	[CONTACT_CREATION_RULES](state, contactCreationRules) {
		state.contactCreationRules = contactCreationRules;
	}
};
