import difference from 'lodash/difference';
import { vsprintf } from 'sprintf-js';

/**
 * @param {Object} error
 * @return {String}
 */
export function mapErrorMessage(error) {
	const {
		message,
		messageArguments
	} = error;
	let errorMessage = error.message;

	if (messageArguments) {
		errorMessage = vsprintf(message, messageArguments);
	}

	return `<span>${errorMessage}</span>`;
}

/**
 * @param {Array} errors
 * @param {Array} contexts
 * @return {Objext}
 */
export function groupErrors(errors, contexts) {
	const generalErrors = errors
		.filter(error => error)
		.filter(
			error => !contexts || !contexts.includes(error.context) || error.messageArguments
		);

	const contextualErrors = difference(errors, generalErrors);

	return {
		generalErrors,
		contextualErrors
	};
}

/**
 * Base class for API errors.
 */
export class BaseException extends Error {
	/**
	 * @param {Object} result
	 */
	constructor(result) {
		super(result);

		this.errors = result.errors;
		this.message = result.errors.reduce(
			(message, error) => `${message}\n ${error.name} ${error.error} ${error.message}`, ''
		);
	}
}
