//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		icon: {
			type: String,
			required: true
		},
		route: {
			type: [String, Object],
			required: true
		},
		isExternalLink: {
			type: Boolean,
			default: false
		}
	}
};

