export const getInitialState = () => ({
	isDialogContactsImportVisible: false,
	selectedFile: {},
	isLoading: false,
	isOptInConfirmed: false,
	isOptInError: false,
	segmentName: '',
	delimiter: ',',
	generalErrors: [],
	isAppAuthDialogVisible: false,
	appAuthData: {},
	appAuthAppId: '',
	appAuthAccountId: '',
	appAuthApiKey: '',
	appAuthErrors: [],
	isWpPluginAuthDialogVisible: false,
	wpPluginAuthPluginName: '',
	wpPluginAuthErrors: []
});
