//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
	Apps
} from 'utils/constants';

import AppAuthenticationDialogCopper from './AppAuthenticationDialogCopper.vue';
import AppAuthenticationDialogActivecampaign from './AppAuthenticationDialogActivecampaign.vue';
import AppAuthenticationDialogAirtable from './AppAuthenticationDialogAirtable.vue';
import AppAuthenticationDialogSendinblue from './AppAuthenticationDialogSendinblue.vue';
import AppAuthenticationDialogKlaviyo from './AppAuthenticationDialogKlaviyo.vue';

const EVENT_SAVE = 'save';
const EVENT_CLOSE = 'close';
const EVENT_CHANGE_AUTH_DATA = 'change:auth-data';

export default {
	components: {
		AppAuthenticationDialogCopper,
		AppAuthenticationDialogActivecampaign,
		AppAuthenticationDialogAirtable,
		AppAuthenticationDialogSendinblue,
		AppAuthenticationDialogKlaviyo
	},

	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		appId: {
			type: String,
			required: true
		},
		authData: {
			type: Object,
			required: true
		},
		errors: {
			type: Array,
			default: null
		}
	},

	computed: {
		isAppCopper() {
			return this.appId === Apps.COPPER;
		},
		isAppActiveCampaign() {
			return this.appId === Apps.ACTIVECAMPAIGN;
		},
		isAppAirtable() {
			return this.appId === Apps.AIRTABLE;
		},
		isAppSendinblue() {
			return this.appId === Apps.SENDINBLUE;
		},
		isAppKlaviyo() {
			return this.appId === Apps.KLAVIYO;
		}
	},

	methods: {
		onSave() {
			this.$emit(EVENT_SAVE);
		},
		onClose() {
			this.$emit(EVENT_CLOSE);
		},
		onChangeAuthData(authData) {
			this.$emit(EVENT_CHANGE_AUTH_DATA, authData);
		}
	}
};

